import React, { useState, useEffect, useRef } from "react";
import { TextField, Radios, Checkboxes } from "mui-rff";
import { useParams } from "react-router-dom";
import { Button, Box, Typography, Grid, Card, CardContent, Stack, Divider, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Facebook, LinkedIn, Twitter, Instagram, Language, Email, WhatsApp } from "@mui/icons-material";
import styles from "./Thankyou.module.scss";

export default function Thankyou() {
  const [haveFeedback, setHaveFeedback] = React.useState(false);
  let { consentId } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [practiceDataLink, setPracticeDataLink] = useState({
    website: "",
    email: "",
    whatsapp: "",
    instagram: "",
    tiktok: "",
    facebook: "",
    linkedin: "",
     googlemap:"",
     googlereviews: "",
  });
  const [googleReviews, setGoogleReviews] = useState([]);


  const { data, error } = useQuery(["siteConsentClauseData"], async () => {
    try {
      const { data: consentData } = await axios.get(`/patient/consent_bundle/${consentId}`);
  
      const response = await axios.get(`/site/${consentData.site_id}/get_site_links`);
      console.log("API Response:", response.data.links); // Log the response here
  
      setPracticeDataLink({
        website: response.data.links.website || "",
        email: response.data.links.email || "",
        whatsapp: response.data.links.whatsapp || "",
        instagram: response.data.links.instagram || "",
        tiktok: response.data.links.tiktok || "",
        facebook: response.data.links.facebook || "",
        linkedin: response.data.links.linkedin || "",
        googlemap: response.data.links.googlemap || "",
        googlereviews: response.data.links.googlereviews || "",
      });
  
    } catch (error) {
      console.error("Error fetching site consent clause data:", error);
      throw error;
    }
  });

  useEffect(() => {
    if (practiceDataLink.googlereviews) {
      const fetchGoogleReviews = async () => {
        try {
          const response = await axios.get(practiceDataLink.googlereviews);
          console.log(response.data);  // Log the entire response to check if reviews are in the result
          if (response.data.result && response.data.result.reviews) {
            setGoogleReviews(response.data.result.reviews);
          } else {
            console.log("No reviews found for this place.");
          }
        } catch (error) {
          console.error("Error fetching Google Reviews:", error);
        }
      };
  
      fetchGoogleReviews();
    }
  }, [practiceDataLink.googlereviews]);
  
  

  const feedback = useMutation(
    (data) => axios.post(`/patient/feedback?consent_bundle_id=${consentId}`, data),
    {
      onSuccess: () => setHaveFeedback(true),
    }
  );

  const sendFeedback = (data: any) => {
    feedback.mutate(data);
  };

  return (
    <Box
      className={styles.thankyou}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        px: 2,
        py: 4,
      }}
    >
      <Card
        sx={{
          maxWidth: { xs: "95%", sm: "600px", md: "700px" },
          width: "100%",
          boxShadow: 4,
          borderRadius: 4,
          p: { xs: 2, sm: 4 },
        }}
      >


        <CardContent>
          <Typography variant="h4" fontWeight={700} textAlign="center" gutterBottom sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}>
            Thank You! 🎉
          </Typography>
          <Typography variant="h6" textAlign="center" sx={{ color: "success.main", fontWeight: 600, fontSize: { xs: "1rem", md: "1.2rem" } }}>
            Signed successfully
          </Typography>

          {/* 🔹 Social Media Links Section */}
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" textAlign="center">Follow us on social media:</Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
           
          {practiceDataLink.email && (
  <IconButton
    color="primary"
    href={`mailto:${practiceDataLink.email}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Email sx={{ color: "#D32F2F" }} /> {/* Red */}
  </IconButton>
)}
             {practiceDataLink.whatsapp && (
              <IconButton
                color="primary"
                href={`https://wa.me/${practiceDataLink.whatsapp.replace(/[^0-9]/g, '')}`} 
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#1877F2" }}
              >
                <WhatsApp sx={{ color: "#25D366" }} />
              </IconButton>
            )}

           {practiceDataLink.website && (
            <IconButton
              color="primary"
              href={practiceDataLink.website.startsWith("http") ? practiceDataLink.website : `https://${practiceDataLink.website}`} 
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#1877F2" }}
            >
              <Language sx={{ color: "#4CAF50" }} />
            </IconButton>
          )}

            {practiceDataLink.instagram && (
              <IconButton color="primary" href={practiceDataLink.instagram} target="_blank" sx={{ color: "#E4405F" }}>
                <Instagram fontSize="large" />
              </IconButton>
            )}
             {practiceDataLink.facebook && (
              <IconButton color="primary" href={practiceDataLink.facebook} target="_blank" sx={{ color: "#1877F2" }}>
                <Facebook fontSize="large" />
              </IconButton>
            )}
            {practiceDataLink.linkedin && (
              <IconButton color="primary" href={practiceDataLink.linkedin} target="_blank" sx={{ color: "#0A66C2" }}>
                <LinkedIn fontSize="large" />
              </IconButton>
            )}
            {practiceDataLink.tiktok && (
              <IconButton color="primary" href={practiceDataLink.tiktok} target="_blank">
                <img src="/tiktok.png" alt="TikTok" style={{ width: 25, height: 25 }} />
              </IconButton>
            )}
            
          
          </Stack>

          <Divider sx={{ my: 3 }} />
          <Typography variant="h6" textAlign="center">Locate Us:</Typography>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {practiceDataLink.googlemap && (
              <IconButton color="primary" href={practiceDataLink.googlemap} target="_blank">
                <img src="/map.png" alt="Google Maps" style={{ width: 35, height: 35 }} />
              </IconButton>
            )}
          </Stack>

         
              <Divider sx={{ my: 3 }} />
              {/* <Typography variant="h6" textAlign="center">Reviews Us:</Typography> */}
              <Stack direction="row" justifyContent="center" spacing={2}>
            {practiceDataLink.googlereviews && (
              <IconButton color="primary" href={practiceDataLink.googlereviews} target="_blank">
                <img src="/123.png" alt="Google Maps" style={{ width: 180, height: 90 }} />
              </IconButton>
            )}
          </Stack>
            

            {!haveFeedback && (

              <>
                <Divider sx={{ my: 3 }} />
                <Card sx={{ p: 3, boxShadow: 3, borderRadius: 2 }}>
                <Typography variant="h6" textAlign="center" sx={{ mb: 3, fontWeight: 600, fontSize: { xs: "1.2rem", md: "1.5rem" } }}>
                  Share Your Feedback to Help Us Improve!
                </Typography>
                  <Form
                    onSubmit={sendFeedback}
                    initialValues={{ voucher: false, message: "" }}
                    render={({ handleSubmit, values }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <Stack spacing={3}>
                          {/* Feedback Questions */}
                          {[
                            "It was helpful to have information about my treatment in video form.",
                            "I feel that I understand my treatment better.",
                            "I feel more in control of my treatment.",
                            "I feel more trust towards my dentist.",
                            "I feel more relaxed going into my next appointment.",
                            "I am glad that my dentist sent me this educational material.",
                          ].map((question, index) => (
                            <Radios
                              key={index}
                              label={question}
                              name={`question${index}`}
                              required
                              className={styles.radio}
                              data={[
                                { label: "😞", value: "1" },
                                { label: "🙁", value: "2" },
                                { label: "😐", value: "3" },
                                { label: "🙂", value: "4" },
                                { label: "😃", value: "5" },
                              ]}
                            />
                          ))}

                          {/* Message Text Field */}
                          <TextField
                            label="What could be improved?"
                            name="message"
                            type="text"
                            multiline
                            variant="outlined"
                            fullWidth
                            sx={{
                              fontSize: { xs: "1rem", md: "1.1rem" },
                              bgcolor: "background.paper",
                              borderRadius: 1,
                              "& .MuiOutlinedInput-root": {
                                padding: "10px",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "primary.main",
                              },
                            }}
                          />

                          {/* Voucher Checkbox */}
                          <Checkboxes
                            name="voucher"
                            data={{
                              label: "I am happy for Medicube Health to contact me for more feedback and a chance to win a £150 Amazon voucher.",
                              value: true,
                            }}
                          />

                          {/* Additional Fields when Voucher is selected */}
                          {values.voucher && (
                            <Stack spacing={2}>
                              <TextField label="Name" name="name" type="text" required variant="outlined" fullWidth />
                              <TextField label="Phone" name="phone" type="text" required variant="outlined" fullWidth />
                              <TextField label="Email" name="email" type="email" required variant="outlined" fullWidth />
                            </Stack>
                          )}

                          {/* Error Handling */}
                          {feedback.isError && (
                            <Typography variant="body1" color="error" textAlign="center">
                              Something went wrong. Please fill all required fields and try again.
                            </Typography>
                          )}

                          {/* Buttons */}
                          <Grid container spacing={2} sx={{ flexDirection: { xs: "column", sm: "row" }, mt: 3 }}>
                            <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                size="large"
                                sx={{
                                  fontSize: { xs: "1rem", md: "1.2rem" },
                                  py: 1.5,
                                  "&:hover": { backgroundColor: "#f4f4f4" },
                                }}
                                onClick={() => setHaveFeedback(true)}
                              >
                                Skip
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                  fontSize: { xs: "1rem", md: "1.2rem" },
                                  py: 1.5,
                                  "&:hover": { backgroundColor: "primary.dark" },
                                }}
                                type="submit"
                                disabled={feedback.isLoading}
                              >
                                {feedback.isLoading ? "Submitting..." : "Submit Feedback"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Stack>
                      </form>
                    )}
                  />
                </Card>
              </>
            )}

        </CardContent>
      </Card>
    </Box>
  );
}
